/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
    isSplash: true, // Change this to false if you don't want Splash screen.
  };
  
  //SEO Related settings
  const seo = {
    title: "Zuhal's Portfolio",
    description:
      "A dedicated professional with an unwavering passion, I am driven to contribute to the development of comprehensive, end-to-end solutions that not only foster sustainable and scalable technical systems but also make a meaningful impact on both social and technological landscapes.",
    og: {
      title: "Zuhal Basharat Portfolio",
      type: "website",
      url: "http://zuhalbasharat.com/",
    },
  };
  
  //Home Page
  const greeting = {
    title: "Zuhal Basharat",
    logo_name: "zuhalbasharat",

    subTitle:
      "A dedicated professional with an unwavering passion, I am driven to contribute to the development of comprehensive, end-to-end solutions that not only foster sustainable and scalable technical systems but also make a meaningful impact on both social and technological landscapes.",
    resumeLink:
      "https://drive.google.com/file/d/1FL_wydnPF2SWCdw2goYAgZ1KKBx8TpcD/view?usp=sharing",
    portfolio_repository: "https://github.com/bzuhal/",
    githubProfile: "https://github.com/bzuhal",
  };
  
  const socialMediaLinks = [
    /* Your Social Media Link */
    // github: "https://github.com/bzuhal",
    // linkedin: "https://www.linkedin.com/in/zuhal-basharat-88710b138/",
    // gmail: "zuhalbasharat98@gmail.com",
    // gitlab: "https://gitlab.com/zuhalbasharat98",
    // facebook: "https://www.facebook.com/laymanbrother.19/",
    // twitter: "https://twitter.com/zuhal_1919",
    // instagram: "https://www.instagram.com/layman_brother/"
  
    {
      name: "Github",
      link: "https://github.com/bzuhal",
      fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
      backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
    },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/in/zuhal-basharat/",
      fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
      backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
    },
    // {
    //   name: "YouTube",
    //   link: "https://youtube.com/c/DevSense19",
    //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
    // },
    {
      name: "Gmail",
      link: "mailto:basharat.zuhal@gmail.com",
      fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
      backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
    },
    // {
    //   name: "X-Twitter",
    //   link: "https://twitter.com/zuhal_1919",
    //   fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    //   backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
    // },
    {
      name: "Facebook",
      link: "https://www.facebook.com/zuhalbasharat",
      fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
      backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
    },
    {
      name: "Instagram",
      link: "https://www.instagram.com/zuhalbasharat/",
      fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
      backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
    },
  ];
  
  const skills = {
    data: [
   
      {
        title: "Full Stack Development",
        fileName: "FullStackImg",
        skills: [
          "⚡ Building responsive website front end ",
          "⚡ Developing mobile applications using Flutter, Ionic",
          "⚡ Creating application backend ",
        ],
        softwareSkills: [
          {
            skillName: "PHP",
            fontAwesomeClassname: "simple-icons:php",
            style: {
              color: "#E34F26",
            },
          },
          {
            skillName: "HTML5",
            fontAwesomeClassname: "simple-icons:html5",
            style: {
              color: "#E34F26",
            },
          },
          {
            skillName: "CSS3",
            fontAwesomeClassname: "fa-css3",
            style: {
              color: "#1572B6",
            },
          },
          {
            skillName: "Sass",
            fontAwesomeClassname: "simple-icons:sass",
            style: {
              color: "#CC6699",
            },
          },
          {
            skillName: "JavaScript",
            fontAwesomeClassname: "simple-icons:javascript",
            style: {
              backgroundColor: "#000000",
              color: "#F7DF1E",
            },
          },
          {
            skillName: "ReactJS",
            fontAwesomeClassname: "simple-icons:react",
            style: {
              color: "#61DAFB",
            },
          },
          {
            skillName: "NPM",
            fontAwesomeClassname: "simple-icons:npm",
            style: {
              color: "#CB3837",
            },
          },
          {
            skillName: "Yarn",
            fontAwesomeClassname: "simple-icons:yarn",
            style: {
              color: "#2C8EBB",
            },
          },
          {
            skillName: "python",
            fontAwesomeClassname: "simple-icons:python",
            style: {
              color: "#663399",
            },
          }, 
          {
            skillName: "NodeJS",
            fontAwesomeClassname: "simple-icons:nodedotjs",
            style: {
              color: "#339933",
            },
          },
          {
            skillName: "Mysql",
            fontAwesomeClassname: "simple-icons:mysql",
            style: {
              color: "#339933",
            },
          },{
            skillName: "Mongodb",
            fontAwesomeClassname: "simple-icons:mongodb",
            style: {
              color: "#339933",
            },
          }
        ],
      }
      // {
      //   title: "Cloud Infra-Architecture",
      //   fileName: "CloudInfraImg",
      //   skills: [
      //     "⚡ Experience working on multiple cloud platforms",
      //     "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
      //     "⚡ Deploying deep learning models on cloud to use on mobile devices",
      //     "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      //   ],
      //   softwareSkills: [
      //     {
      //       skillName: "GCP",
      //       fontAwesomeClassname: "simple-icons:googlecloud",
      //       style: {
      //         color: "#4285F4",
      //       },
      //     },
      //     {
      //       skillName: "AWS",
      //       fontAwesomeClassname: "simple-icons:amazonaws",
      //       style: {
      //         color: "#FF9900",
      //       },
      //     },
      //     {
      //       skillName: "Azure",
      //       fontAwesomeClassname: "simple-icons:microsoftazure",
      //       style: {
      //         color: "#0089D6",
      //       },
      //     },
      //     {
      //       skillName: "Firebase",
      //       fontAwesomeClassname: "simple-icons:firebase",
      //       style: {
      //         color: "#FFCA28",
      //       },
      //     },
      //     {
      //       skillName: "PostgreSQL",
      //       fontAwesomeClassname: "simple-icons:postgresql",
      //       style: {
      //         color: "#336791",
      //       },
      //     },
      //     {
      //       skillName: "MongoDB",
      //       fontAwesomeClassname: "simple-icons:mongodb",
      //       style: {
      //         color: "#47A248",
      //       },
      //     },
      //     {
      //       skillName: "Docker",
      //       fontAwesomeClassname: "simple-icons:docker",
      //       style: {
      //         color: "#1488C6",
      //       },
      //     },
      //     {
      //       skillName: "Kubernetes",
      //       fontAwesomeClassname: "simple-icons:kubernetes",
      //       style: {
      //         color: "#326CE5",
      //       },
      //     },
      //   ],
      // },
    
    ],
  };
  
  // Education Page
  const competitiveSites = {
    competitiveSites: [
      {
        siteName: "LeetCode",
        iconifyClassname: "simple-icons:leetcode",
        style: {
          color: "#F79F1B",
        },
        profileLink: "https://leetcode.com/layman_brother/",
      },
      {
        siteName: "HackerRank",
        iconifyClassname: "simple-icons:hackerrank",
        style: {
          color: "#2EC866",
        },
        profileLink: "https://www.hackerrank.com/layman_brother",
      },
      {
        siteName: "Codechef",
        iconifyClassname: "simple-icons:codechef",
        style: {
          color: "#5B4638",
        },
        profileLink: "https://www.codechef.com/users/zuhal_1919",
      },
      {
        siteName: "Codeforces",
        iconifyClassname: "simple-icons:codeforces",
        style: {
          color: "#1F8ACB",
        },
        profileLink: "http://codeforces.com/profile/layman_brother",
      },
      {
        siteName: "Hackerearth",
        iconifyClassname: "simple-icons:hackerearth",
        style: {
          color: "#323754",
        },
        profileLink: "https://www.hackerearth.com/@zuhal391",
      },
      {
        siteName: "Kaggle",
        iconifyClassname: "simple-icons:kaggle",
        style: {
          color: "#20BEFF",
        },
        profileLink: "https://www.kaggle.com/laymanbrother",
      },
    ],
  };
  
  const degrees = {
    degrees: [
      {
        title: "Passau university",
        subtitle: "M. in Computer Science",
        logo_path: "passau-uni.png",
        alt_name: "Passau university",
        duration: "2021 - now",
        descriptions: [
          "⚡ I have studied Computer sciecne subjects like AI Engineering L, Requirment Enginering, Princeple of Data Organization, Sementic Data Intergration, Advanced Object Orianted Programming using C++, Data Visualization etc.",
  
        ],
        website_link: "https://www.uni-passau.de/en/",
      },
      {
        title: "Kabul University",
        subtitle: "B.S. in Computer Science",
        logo_path: "kabul-uni.png",
        alt_name: "Kabul University",
        duration: "2013 - 2017",
        descriptions: [
          "⚡ I have taken varity of courses related to Software enginnering.",
        ],
        website_link: "https://ku.edu.af/en",
      },
    ],
  };
  
  const certifications = {
    certifications: [
      {
        title: "Python Programming",
        subtitle: "Programming for Everybody (Getting Started with Python)",
        logo_path: "U-M-logo.png",
        certificate_link:
          "https://coursera.org/share/b7207fd1f2559420f7c437651352591f",
        alt_name: "University Of Michigan",
        color_code: "#1F70C199",
      },
      {
        title: "Python Programming",
        subtitle: "Using Python to Access Web Data",
        logo_path: "U-M-logo.png",
        certificate_link:
          "https://coursera.org/share/ee8fa0781621c7540d4878fe6672f626",
        alt_name: "University Of Michigan",
        color_code: "#FFBB0099",
      },
      {
        title: "Python Programming",
        subtitle: "Python Data Structures",
        logo_path: "U-M-logo.png",
        certificate_link:
          "https://coursera.org/share/f4b94d8fc1db7d5e3e914294c21c6303",
        alt_name: "University Of Michigan",
        color_code: "#8C151599",
      },
      {
        title: "Python Programming",
        subtitle: "Using Databases with Python",
        logo_path: "U-M-logo.png",
        certificate_link:
          "https://coursera.org/share/1a0322d1354910fc6a5384d9b369bbe4",
        alt_name: "University Of Michigan",
        color_code: "#2A73CC",
      },
      {
        title: "Python Programming",
        subtitle: "Capstone: Retrieving, Processing, and Visualizing Data with Python",
        logo_path: "U-M-logo.png",
        certificate_link:
          "https://coursera.org/share/ce5ea9ffcbfdb9534f23f65ea50ffd08",
        alt_name: "University Of Michigan",
        color_code: "#8C151599",
      },
      {
        title: "Mobil App Development",
        subtitle: "Training",
        logo_path: "technation.png",
        certificate_link:
          "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
        alt_name: "Tech Nation",
        color_code: "#1F70C199",
      },
      {
        title: "ICT Website Design",
        subtitle: "Training",
        logo_path: "usaid.png",
        certificate_link:
          "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
        alt_name: "USAID",
        color_code: "#0C9D5899",
      },
      {
        title: "Capacity Building",
        subtitle: "Markiting, CRM, Finance, Company Profile, Prposal writing, HR managment and Project Managment",
        logo_path: "moct.png",
        certificate_link:
          "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
        alt_name: "Ministry of Communication and technology - Afghanistan",
        color_code: "#00000099",
      },
      // {
      //   title: "Fullstack Development",
      //   subtitle: "- Jogesh Muppala",
      //   logo_path: "coursera_logo.png",
      //   certificate_link:
      //     "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      //   alt_name: "Coursera",
      //   color_code: "#2A73CC",
      // },
      
    ],
  };
  
  // Experience Page
  const experience = {
    title: "Experience",
    subtitle: "Work and Volunteership",
    description:
      "I have worked with many evolving startups, Designer and Software Architect. I have also worked with some well established companies mostly as Full stack Developer. I love organising events.",
    header_image_path: "experience.svg",
    sections: [
      {
        title: "Work",
        work: true,
        experiences: [
          {
            title: "Work Student (Web Developer and Data analyzer)",
            company: "University of Passau",
            company_url: "https://www.uni-passau.de/",
            logo_path: "passau-uni.png",
            duration: "June 2023 - Nov 2023",
            location: "Passau, Germany",
            description:
              "I meticulously analyzed and cleaned raw data to ensure accuracy, designed MongoDB schemas for efficient data management, and developed a web application using Node.js to enhance functionality and user experience.",
            color: "#0879bf",
          },
          {
            title: "Work Student (Web Developer)",
            company: "Langer and Friends,",
            company_url: "https://laf-brandgroup.com/",
            logo_path: "landf.png",
            duration: "March 2022 - Sep 2022",
            location: "Passau, Germany",
            description:
              "I developed web applications utilizing Shopify, React, and WordPress. This included the end-to-end process from design to implementation. In addition, I played a key role in fostering a collaborative environment within the team, contributing to effective teamwork and ensuring the successful delivery of projects.",
            color: "#9b1578",
          },
          {
            title: "Full Stack Developer",
            company: "Ministry of Public Works",
            company_url: "https://mopw.gov.af/en",
            logo_path: "mopw.png",
            duration: "Jul 2017 - May 2021",
            location: "Kabul, Afghanistan",
            description:
              "I spearheaded the development of robust systems by creating front-end and back-end code using PHP, JavaScript, and MySQL. My responsibilities encompassed thorough testing, debugging, and ongoing maintenance to guarantee optimal system performance. Taking on a leadership role, I managed technical teams to ensure efficient collaboration and successful deployment. Simultaneously, I provided essential technical support, authored comprehensive documentation, and actively fostered a teamwork-oriented environment.",
            color: "#fc1f20",
          },
        ],
      },
      // {
      //   title: "Internships",
      //   experiences: [
      //     {
      //       title: "Machine Learning Intern",
      //       company: "TikTok Inc.",
      //       company_url: "https://www.tiktok.com/en/",
      //       logo_path: "tiktok_logo.png",
      //       duration: "May 2022 - Aug 2022",
      //       location: "San Francisco, USA",
      //       description:
      //         "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
      //       color: "#000000",
      //     },
      //     {
      //       title: "Data Science Research Intern",
      //       company: "Delhivery Pvt. Ltd.",
      //       company_url: "https://www.delhivery.com/",
      //       logo_path: "delhivery_logo.png",
      //       duration: "May 2019 - Sept 2019",
      //       location: "Gurgaon, Haryana",
      //       description:
      //         "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
      //       color: "#ee3c26",
      //     },
      //     {
      //       title: "Data Science Intern",
      //       company: "Intel Indexer LLC",
      //       company_url:
      //         "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
      //       logo_path: "intel_logo.jpg",
      //       duration: "Nov 2018 - Dec 2018",
      //       location: "Work From Home",
      //       description:
      //         "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
      //       color: "#0071C5",
      //     },
      //   ],
      // },
      {
        title: "Volunteerships",
        experiences: [
          {
            title: "Software Developer",
            company: "Ministry of Interior Affairs",
            company_url: "https://moi.gov.af/en",
            logo_path: "moia.png",
            duration: "June 2019 - Oct 2019",
            location: "Kabul, Afghanistan",
            description:
              "I played a pivotal role in the development of a dedicated system for martyrs and disabled soldiers, employing PHP, JavaScript, and MySQL. Beyond coding, I took charge of cleaning, organizing, and seamlessly migrating soldiers' data into the system and a new database. This comprehensive approach ensured the efficient operation of the system while maintaining the integrity of critical data for martyrs and disabled soldiers.",
            color: "#4285F4",
          },
          {
            title: "Organizer and Instructor",
            company: "Tech Women",
            company_url: "https://www.microsoft.com/",
            logo_path: "tech-wom.png",
            duration: "may 2013 - May 2016",
            location: "Kabul, Afghanistan",
            description:
              "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
            color: "#D83B01",
          },
        ],
      },
    ],
  };
  
  // Projects Page
  const projectsHeader = {
    title: "Projects",
    description:
      "My projects makes use of vast variety of latest technology tools.",
    avatar_image_path: "projects_image.svg",
  };
  
  const publicationsHeader = {
    title: "Publications",
    description:
      "I have worked on and published a few research papers and publications of my own.",
    avatar_image_path: "projects_image.svg",
  };
  
  const publications = {
    data: [
      {
        id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
        name: "Artificial Intelligence Paper",
        createdAt: "2020-03-06T16:26:54Z",
        description: "Paper Written on Artificial Intelligence published in xyz ",
        url:
          "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
      },
      {
        id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
        name: "Artificial Intelligence Paper",
        createdAt: "2020-03-06T16:26:54Z",
        description: "Paper Written on Artificial Intelligence published in xyz ",
        url:
          "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
      },
      {
        id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
        name: "Artificial Intelligence Paper",
        createdAt: "2020-03-06T16:26:54Z",
        description: "Paper Written on Artificial Intelligence published in xyz ",
        url:
          "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
      },
      {
        id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
        name: "Artificial Intelligence Paper",
        createdAt: "2020-03-06T16:26:54Z",
        description: "Paper Written on Artificial Intelligence published in xyz ",
        url:
          "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
      },
      {
        id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
        name: "Artificial Intelligence Paper",
        createdAt: "2020-03-06T16:26:54Z",
        description: "Paper Written on Artificial Intelligence published in xyz ",
        url:
          "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
      },
    ],
  };
  
  // Contact Page
  const contactPageData = {
    contactSection: {
      title: "Contact Me",
      profile_image_path: "animated_zuhal.png",
      description:
        "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with, PHP, Javascript, React, python.",
    },
    blogSection: {
      title: "Blogs",
      subtitle:
        "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
      link: "https://blogs.zuhalbasharat.com/",
      avatar_image_path: "blogs_image.svg",
    },
    addressSection: {
      title: "Address",
      subtitle:
        "Passau, Germany",
      locality: "Kanodar",
      country: "IN",
      region: "Gujarat",
      postalCode: "385520",
      streetAddress: "Ambavadi vas",
      avatar_image_path: "address_image.svg",

    },
    phoneSection: {
      title: "",
      subtitle: "",
    },
  };
  
  export {
    settings,
    seo,
    greeting,
    socialMediaLinks,
    skills,
    competitiveSites,
    degrees,
    certifications,
    experience,
    projectsHeader,
    publicationsHeader,
    publications,
    contactPageData,
  };
  